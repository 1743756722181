<template>
  <div class="wrapper">
    <div class="bet bet-common">
      <div class="bet-content">
        <div class="bet-match-display-id">{{ matchDisplayId }}</div>
        <div class="bet-time">{{ matchTimeShort }}</div>
        <div class="bet-match-name">
          <span class="bet-match-name-text">{{ bet.betEventName }}</span>
        </div>
        <div class="bet-name">
          <span class="bet-name-text">{{ bet.betName }}</span>
        </div>
        <div class="bet-outcome-name">{{ bet.betOutcomeName }}</div>

        <div class="bet-outcome-odd">
          <span :class="{'bet-outcome-odd-void': status === 'VOID'}">
            <span v-if="!bet.betOutcomeOdd"> - </span>
            <span v-else>{{ bet.betOutcomeOdd | odd }} </span>
          </span>
          <span v-if="status === 'VOID'">1.00</span>
        </div>

        <div class="bet-result">
          {{ bet.result || '-' }}
        </div>
        <div
          class="bet-status"
          :class="`status-color-${statusColor}`"
        >
          <i class="material-icons">
            {{ statusIcon }}
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { odd } from '@/filters';
import {
  format,
  toDate,
} from 'date-fns';

export default {
  name: 'PreviewBetCheckedCommon',
  filters: { odd },
  props: {
    bet: Object,
    color: String,
    product: String,
  },
  data() {
    return {
      statusIcons: {
        LOST: 'cancel',
        WON: 'check_circle',
        OPEN: 'schedule',
        VOID: 'do_disturb_on',
      },
      statusColors: {
        LOST: 'red',
        WON: 'green',
        OPEN: 'default',
        VOID: 'gray',
      },
    };
  },
  computed: {
    matchDisplayId() {
      return this.bet.betId;
    },
    matchTimeShort() {
      return this.bet.betStartTimeUTC ? this.formatTime('dd.MM | HH:mm') : this.bet.betStartTime;
    },
    status() {
      return this.bet.resolutionStatus;
    },
    statusColor() {
      return this.statusColors[this.status];
    },
    statusIcon() {
      return this.statusIcons[this.status];
    },
  },
  methods: {
    formatTime(dateFormat) {
      return format(toDate(this.bet.betStartTimeUTC), dateFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet {
    color: #161616;
    background-color: #f5f5f5;
    height: 30px;
    border-radius: 3px;
  }

  .bet-common {
    position: relative;
    width: 100%;
    display: flex;

    .bet-display-id {
      font-weight: 700;
      padding-right: 0.5em;
    }

    .bet-content {
      line-height: 1.2;
      width: 100%;
      display: flex;

      & > div {
        flex-basis: 20%;
        padding: .2em;
        display: flex;
        align-items: center;

      }

      .bet-match-display-id {
        flex-basis: 6%;
        font-weight: 700;
      }

      .bet-time {
        flex-basis: 11%;
      }

      .bet-result {
        flex-basis: 8%;
        font-weight: 700;
      }

      .bet-outcome-odd {
        font-weight: 700;
        flex-basis: 6%;

        &-void {
          text-decoration: line-through;
          margin-right: 2px;
        }
      }

      .bet-outcome-name {
        font-weight: 700;
        flex-basis: 11%;
      }

      .bet-status {
        flex-basis: 5%;
        justify-content: center;
        border-radius: 3px;
      }

      .bet-match-name {
        flex-basis: 30%;
        font-weight: 700;
        overflow: hidden;
      }

      .bet-name {
        flex-basis: 23%;
        overflow: hidden;
      }

      .bet-match-name-text,
      .bet-name-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .status-color {
      &-default {
        background: transparent;
      }

      &-red {
        background: #e42c0d;
        color: #fff;
      }

      &-green {
        background: #2c9a5b;
        color: #fff;
      }

      &-gray {
        background: #808080;
        color: #fff;
      }

      &-orange {
        background: #dfbf52;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 600px) and (max-height: 800px) {
  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 800px) and (max-height: 600px) {
  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1280px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1280px) and (min-height: 1024px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1600px) and (min-height: 900px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 900px) and (min-height: 1600px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .bet-content {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1080px) and (min-height: 1920px) {
  .bet-content {
    font-size: 18px !important;
  }
}
</style>

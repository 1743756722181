<template>
  <div class="preview-body">
    <div class="preview-placeholder">
      <img
        v-if="logo"
        :src="logo"
        alt="''"
        class="preview-placeholder-logo"
      />
      <span class="preview-placeholder-text">
        {{ placeholder }}
      </span>
    </div>
    <div class="preview-content">
      <div class="preview-content-wrap">
        <PreviewBetsHeader />
        <div class="bets">
          <div class="bets-items clearfix">
            <div
              class="bets-item list"
              v-for="bet in bets"
              :key="bet.uid"
            >
              <PreviewBet
                :bet="bet"
                :color="color"
              />
            </div>
          </div>
        </div>
        <PreviewFooter
          :bonus="bonus"
          :bonus-percentage="bonusPercentage"
          :combinations="combinations"
          :payment="payment"
          :stake="stake"
          :min-winning="minWinning"
          :max-winning="maxWinning"
          :max-winning-label="maxWinningLabel"
          :payin-tax="payinTax"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewBet from './PreviewBetCommon';
import PreviewFooter from '../PreviewFooter';
import PreviewBetsHeader from './PreviewBetsHeader';
import config from '../../../config';

export default {
  name: 'PreviewBodyCommon',
  components: {
    PreviewBet,
    PreviewFooter,
    PreviewBetsHeader,
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    bets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const { logo, color, placeholder } = config;

    return { logo, color, placeholder };
  },
  computed: {
    bonus() {
      return this.ticket.bonus;
    },
    bonusPercentage() {
      return this.ticket.bonusPercentage;
    },
    combinations() {
      return null;
    },
    payinTax() {
      return this.ticket.paymentTax || 0.00;
    },
    payment() {
      return this.ticket.payment;
    },
    stake() {
      return this.ticket.stake;
    },
    minWinning() {
      return this.ticket.minWinning;
    },
    maxWinning() {
      return this.ticket.maxWinning;
    },
    maxWinningLabel() {
      return config.maxPossibleWinLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .preview-body {
    background-color: #242424;
    height: 100%;
    padding-top: 50px;
  }

  .preview-content {
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
  }

  .preview-content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bets {
    color: #fdfdfd;
    height: 100%;
    font-size: 14px;
  }

  .bets-items {
    height: 100%;
    padding: 1px;
    overflow: auto;
  }

  .bets-item {
    float: left;
    padding: 1px;

    &.list {
      width: 100%;
      height: 32px;
      display: flex;

      & > div {
        width: 100%;
      }
    }
  }

  .bet {
    color: #161616;
    background-color: #f5f5f5;
    height: 100%;
    border-radius: 3px;
  }

  &.horizontal {
    .bets {
      padding-bottom: 140px;
    }
  }
}

</style>
